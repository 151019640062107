// views
import { About, Contact, Hero, Demo } from "./views";

// components
import { Menu } from "./components";



function App() {
  return (
    <>
      <Hero />
      <About />
      <Demo />
      <Contact />
      <Menu />
    </>
  );
}

export default App;
